.main-cont-left {
    width: 50vw;
    height: 100vh;
    background-image: url(../Assets/loginsideimg.jpg);
    background-repeat: no-repeat;
    background-size: 50vw 100vh;
    display: flex;
    justify-content: center;
}

.go-back-btn-login {
        position: absolute;
        margin-top: -630px;
        margin-left: 40px;
        color: white;
}

.child-cont-left {
    width: 50%;
    height: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    background-color: rgb(182 182 182 / 15%);
    border-radius: 8px;
}

.text-cont {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.span-text {
    color: white;
    font-size: xxx-large;
    font-weight: 700;
}

.main-cont-right {
    width: 50vw;
    height: 100vh;
    background-color: #000000e8;
    display: flex;
    align-items: center;
}

.login-cont {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
}

.form-cont {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cred-span {
    color: white
}

.cred-input {
    max-width: 350px;
    color: white;
    height: 36px;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.32);
    border-radius: 6px;
    margin-bottom: 20px;
}
.cred-input-cont {
    width: 90%;
    color: white;
    height: 34px;
    background: rgba(255, 255, 255, 0.02);
    border: none;
}
.eye-cont {
    display: flex;
}
#show-pass {
    color: white;
    display: flex;
    justify-content: end;
    height: 36px;
    background: rgba(255, 255, 255, 0.02);
    margin: 0 15px 0 0;
}
.cred-input-cont:focus{
    outline: none;
}
.div-focus {
    border: 1px solid rgb(255, 255, 255);
}
.btn-login {
    width: 192px;
    height: 38px;
    color: white;
    background: linear-gradient(135deg, #9D31A0 0%, rgba(13, 197, 207, 0.75) 100%);
    border-radius: 7px;
    border: none;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.logo-src {
    display: none;
}

@media screen and (max-width: 900px) {
    .main-cont-left {
        display: none;
    }

    .main-cont-right {
        width: 100vw;
    }

    .logo-src {
        display: flex;
    }
}