@media only screen and (max-width: 668px) {
  .navlinks {
    display: none !important;
  }
  .intro-logo {
    width: 220px;
  }
  .tagline-div {
    display: block;
  }
  .quote-container {
    height: 900px !important;
    margin: 0 !important;
  }
  .quote-holder {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .quote-left-div h1 {
    margin-left: 20px !important;
    font-size: 70px !important;
    width: 90%;
    line-height: 60px !important;
    margin-top: 20px !important;
  }
  .quote-left-div img {
    width: 200px !important;
  }
  .quote-left-div p {
    font-size: 30px !important;
    line-height: 28px !important;
    /* margin-left: 20px !important; */
  }

  .more-sec-3{
    padding: 25px;
  }

  .help-div p{
    width: 80%;
  }

  .slogan-text{
    margin-left: 20px;
  }

  .intro-sec{
    align-items: flex-start !important;
    padding-top: 150px;
  }

  .section-2{
    padding-top: 50vh !important;
  }

  .intro-pic{
    position: fixed;
    width: 150px !important;
    z-index: 500;
    top: 380px;
    left: 50%;
    margin-left: -75px; 
  }

  .intro-hand-pic{
    position: absolute;
    width: 300px !important;
    right: 0 !important;
    z-index: 600;
    top:200px !important
  }

  .embi-logo img{
    width: 100%;
  }

  .embi-logo p{
    width: 100%;
  }

  .footer-b2b{
    padding: 10px;
  }

  .parallax {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }


  
}
@media only screen and (max-width: 949px) {
    .nav-main{
        display: none;
    }
    .top-nav{
        display: block;
    }
    .contact-privacy{
      display: none !important;
    }
}


@media only screen and (max-width: 768px) {
  /* .navlinks {
    display: none !important;
  } */

  #quote .box {
    width: 70%;
  }

  .quote-container {
    height: 900px;
  }

  /* .intro-img-col{
    display: none !important;
  } */
  .info-txt{
    font-size: 35px !important;
    line-height: 35px !important;
    text-align: center;
  }
  .info1{
    margin-bottom: 30px !important;
  }
  .info2{
    margin-top: 40px !important;
  }
  .more-img{
    width: 300px !important;
  }
  .more-container{
    padding: 20px;
    height: 600px !important;
  }
  .contact-left{
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 992px) {
  /* .navlinks {
    display: none !important;
  } */

  .intro-pic{
    width: 250px;
    right: 250px;
  }
  
  .intro-hand-pic{
    width: 600px;
    right: 0 !important;
    top:400px
  }


  .quote-left-div h1 {
    font-size: 57px !important;
    line-height: 56px !important;
  }
  .quote-left-div img {
    width: 150px;
  }
  .quote-left-div p {
    font-size: 25px !important;
    line-height: 32px !important;
  }

  #quote .box{
    top:auto !important
  }

  
}
