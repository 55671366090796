.container-about {
    width: 100vw;
    overflow: hidden;
}

/* .image-cont {
  display: flex;
  margin-left: 8vw;
  margin-top: 6vw;
} */
/* .profile-card-holder {
  max-width: 100vw;
} */
.name {
    display: flex;
    /* justify-content: space-between; */
}

.about-img {
    width: 20vw;
}

.cont-text {
    margin: auto;
    padding-left: 35px;
    width: 60vw;
    /* font-size: 100%; */
}

/* .profile-card-holder {
  display: flex;
  max-width: ;
} */
.img-round {
    border-radius: 182px;
    height: 218px;
    width: 218px;
    /* border-radius: 15px; */
}

.about-card {
    display: flex;
    justify-content: center;
}

.Card {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 120px;
    border-top-left-radius: 120px;
}

.join-span {
    /* display: inline;
    position: relative;
    font-family: inherit;
    font-size: inherit;*/
    font-weight: bold;
    /* font-family: auto; */
    font-size: 2rem;
    /* webkit-text-fill-color: transparent;
      webkit-box-decoration-break: clone; */
    background: -webkit-linear-gradient(top, rgb(255, 147, 79), rgb(244, 94, 0));
    color: rgb(244, 94, 0);
}

@media screen and (max-width: 990px) {
    .image-cont {
        /* display: flex; */
        display: flex;
        width: 100vw;
        /* height: 500px;
                align-items: center; */
        justify-content: center;
    }

    .row-rev {
        flex-direction: column-reverse;
    }

    .about-img {
        width: 40vw;
    }
}

.founder-span {
    font-weight: bold;
    /* font-family: auto; */
    font-size: 2rem;
    background: -webkit-linear-gradient(top, #414e61, #0a58ca);
    color: #335a92;
}

img.vert-move {
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 2s infinite alternate;
}

img.vert-move {
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

.animate-character {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #5ddbb5 0%,
            #6CB3FC 29%,
            #1CD69E 67%,
            #1A4B7E 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite;
    display: inline-block;
    font-size: 190px;
    animation-direction: reverse;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.meet {
    color: #335a92;
    font-weight: bold;
    font-size: 2rem;
}