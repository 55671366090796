.privacy-head-div {
  background-image: linear-gradient(#1A4B7E, #6CB3FC);
}

.privacy-heading {
  font-size: 50px;
  margin-bottom: 30px;
  color: white;
  padding-top: 10rem;
  padding-bottom: 30px;
}

.privacy-heading-about-us {
  font-size: 50px;
  color: white;
  padding-top: 10rem;
}

.about-us-subtitle {
  font-size: 1rem;
  color: white;
  /* margin-bottom: 10em; */
}

.privacy-content table {
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.privacy-content a {
  color: rgb(165, 2, 141) !important;
}

/* //////////// TEAMS //////////// */

.main-wrapper {
  background-image: linear-gradient(to right bottom,
      #054c76,
      #0c436b,
      #10395f,
      #113154,
      #112849,
      #182546,
      #1d2242,
      #211f3e,
      #2d2040,
      #382141,
      #432141,
      #4d2240);
}

.profile-card-holder {
  position: relative;
  /* display: flex; */
}

/* ///// */

/* .profile-card {
  width: 200px;
  height: 250px;
  background-size: cover;
  filter: grayscale(1);
}

.profile-card:hover{
  filter: grayscale(0);
}

.profile-card img {
  width: 100%;
  height: 100%;
  filter: grayscale(1);
}

.profile-card .name {
  color: white;
  bottom: 0;
  font-size: 25px;
  text-transform: uppercase;
} */

.profile-card {
  position: absolute;
  width: 200px;
  background-color: #58035e;
}

.details {
  /* position: absolute; */
  color: #054c76;
  top: 220px;
}

.details .name {
  margin-bottom: 0px;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 700;
}

.bb::before,
.bb::after,
.bb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bb {
  width: 200px;
  height: 200px;
  background: url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg/1200px-Elon_Musk_Royal_Society_%28crop2%29.jpg") no-repeat 50%/70% rgba(0, 0, 0, 0.1);
  color: #58035e;
  box-shadow: inset 0 0 0 1px rgba(97, 7, 79, 0.5);
  border-radius: 50%;
}

.bb::before,
.bb::after {
  content: "";
  /* z-index: -1; */
  margin: -5%;
  box-shadow: inset 0 0 0 2px;
  animation: clipMe 8s linear infinite;
}

.bb::before {
  animation-delay: -4s;
}

@keyframes clipMe {

  0%,
  100% {
    clip: rect(0px, 220px, 2px, 0px);
  }

  25% {
    clip: rect(0px, 2px, 220px, 0px);
  }

  50% {
    clip: rect(218px, 220px, 220px, 0px);
  }

  75% {
    clip: rect(0px, 220px, 220px, 218px);
  }
}

.card1 {
  top: 40px;
  left: 1vw;
}

.card2 {
  top: 40px;
  left: 550px;
}

.card3 {
  top: 50px;
  left: 550px;
}

/* body {
  position: relative;
  background-color: #0f222b;
} */

/* *,
*::before,
*::after {
  box-sizing: border-box;
} */

/* //////////////  BLOGS ////////////////// */

.main-container {
  padding-top: 9rem;
  padding-bottom: 5rem;
}

.blog-section .blog-main-heading {
  font-size: 40px;
  width: 80%;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
}

.blog-content pre {
  white-space: pre-wrap;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 0px;
  font-size: 20px;
}

.blog-content pre:first-letter {
  text-transform: capitalize;
  font-size: 35px;
}

.blog-sub-heading {
  color: #580576;
}

.blog-time {
  color: #777a7d;
}

.blog-author {
  font-weight: 700;
  color: #079227;
}

@media (max-width: 767px) {
  .blog-section .blog-main-heading {
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }

  .blog-content pre {
    padding: 10px;
  }
}