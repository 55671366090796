.main-comp-view-blog {
  background-color: black;
  padding-top: 5rem;
  color: white;
}

.Subscribe-span {
  font-weight: 600;
  font-size: 24px;
  color: "#F7F8F8";
}

#Subscribe-span {
  color: #8a8f98;
  font-weight: 500;
  font-size: 15px;
}

#email-input {
  height: 32px;
  background: #060606;
  color: white;
  border: 1px solid #313035;
  border-radius: 6px;
  width: 296.52px;
  padding: 8px 13px;
}

#join-btn {
  width: 58.48px;
  height: 32px;
  color: white;
  border: none;
  background: #9d31a0;
  border-radius: 4px;
}

#scroll-div {
  display: flex;
  gap: 50px;
  margin: auto;
  width: 80vw;
  height: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-align: justify;
  scrollbar-width: thin;
}

#scroll-div::-webkit-scrollbar {
  display: none;
    width: 10px;
  }
  #scroll-div::-webkit-scrollbar-track {
    background-color: darkgrey;
    border-radius: 100px;

  }
  
  #scroll-div::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
  }